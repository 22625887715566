import React, {useState, useEffect}from "react";
import { useNavigate,Link } from "react-router-dom";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import Global from "../../APIConfig";
import OnlineStatus from "../../Onlinestatus";

function Header() {
    let navigate = useNavigate();
    const{headers1,headers2,logout}=AuthUser();
    const [showSelectBox, setShowSelectBox] = useState(false);
    const [filteredData, setFilteredData] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedoption, setSelectedoption] = useState('');
    const [trackingnumber, setTrackingnumber] = useState('');
    const useritems = JSON.parse(localStorage.getItem('user'));
    
    useEffect(()=>{
        const dropdownMenu = document.querySelector('.new_request_menu .dropdown');
        const overlay = document.querySelector('.overlay');

        const showDropdown = () => {
        overlay.classList.add('show');
        };

        const hideDropdown = () => {
        overlay.classList.remove('show');
        };

        if (dropdownMenu) {
        dropdownMenu.addEventListener('show.bs.dropdown', showDropdown);
        dropdownMenu.addEventListener('hide.bs.dropdown', hideDropdown);
        }

        // Cleanup event listeners on component unmount
        return () => {
        if (dropdownMenu) {
            dropdownMenu.removeEventListener('show.bs.dropdown', showDropdown);
            dropdownMenu.removeEventListener('hide.bs.dropdown', hideDropdown);
        }
    };
    },[])
    const items = JSON.parse(localStorage.getItem('user'));

    const userlogout=()=>{
        logout();
        navigate('/');
        window.location.reload();
    }

    const linkdata = [
    {name:'Shipment List',link:'/Shipmentlist/All'},
    {name:'Undelivered Shipment List',link:'/Shipmentlist/B'},
    {name:'Delivered Shipment List',link:'/Shipmentlist/D'},
    {name:'Add Shipment',link:'/Createshipment'},
    {name:'Customer Wallet',link:'/CustomerWallet'},
    {name:'Customer Credit',link:'/CustomerCredit'},
    {name:'Update Profile',link:'/UpdateProfile'},
    {name:'Update Password',link:'/UpdatePassword'},
    ];

    const handlesearchdata=(val)=>{
        setSearchTerm(val);
        if(val.length >2)
        {
            const filtered = linkdata.filter(item => 
                item.name.toLowerCase().includes(val)
            );
            if(filtered.length >0)
            {
                setFilteredData(filtered);
                setShowSelectBox(true);
            }
        }
        else
        {
            setShowSelectBox(false);
        }
    }
    const Trackingshipment=()=>{
        if(trackingnumber !="")
        {
            navigate(`/ShipmentTracking/${trackingnumber}`);
        }
        else
        {
            toast.warn("Please Enter AWB No.");
        }
      }

      const handelSelectedoption=(val)=>{
        localStorage.removeItem('selectedoption');
        localStorage.setItem('selectedoption', JSON.stringify(val));
        setSelectedoption(val);
      }
    return(
        <header>
            <OnlineStatus/>
    <nav className="navbar navbar-top navbar-expand navbar-dashboard navbar-dark ps-0 pe-2 pb-0">
        <div className="container-fluid px-0">
            <div className="d-flex justify-content-between w-100" id="navbarSupportedContent">
            <div className="d-flex align-items-center">
                <form className="navbar-search form-inline" id="navbar-search-main">
                <div className="input-group input-group-merge search-bar">
                    <span className="input-group-text" id="topbar-addon">
                        <svg className="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                        </svg>
                    </span>
                    <input type="text" className="form-control" id="topbarInputIconLeft" placeholder="Search" aria-label="Search" aria-describedby="topbar-addon" value={searchTerm} onChange={(e)=>handlesearchdata(e.target.value)} />
                </div>
                {showSelectBox &&
                <ul className="mastersearch">
                    {filteredData.map((item, index) => (
                        <li key={index} className="searchtypehead">
                            <a href={item.link}>{item.name}</a>
                        </li>
                    ))}
                </ul>
                }
                </form>
            </div>
            {/* <div className="ms-auto ">
                <div className="btn-group">
                    <a href="/Createshipment" className="btn btn-gray-800 d-inline-flex align-items-center me-2 dropdown-toggle">
                        <svg className="icon icon-xs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                    </a>
                </div>
            </div> */}
            <ul className="navbar-nav align-items-center custom_had_nav">
                <li className="nav-item dropdown ms-lg-3">
                <a className="nav-link dropdown-toggle pt-1 px-0" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="media d-flex align-items-center">
                    <img className="avatar rounded-circle" alt="Image placeholder" src={`${Global.logoUrl}${items.customer_image}`} />
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                        <span className="mb-0 font-small fw-bold text-gray-900">{items.name}</span>
                    </div>
                    </div>
                </a>
                <div className="dropdown-menu dashboard-dropdown dropdown-menu-end mt-2 py-1">
                    <a className="dropdown-item d-flex align-items-center" href="/UpdateProfile">
                    <svg className="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clip-rule="evenodd"></path></svg>
                    My Profile
                    </a>
                    <a className="dropdown-item d-flex align-items-center" href="/UpdatePassword">
                    <svg className="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path></svg>
                    Update Password
                    </a>
                    {/* <a className="dropdown-item d-flex align-items-center" href="#">
                    <svg className="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm0 2h10v7h-2l-1 2H8l-1-2H5V5z" clip-rule="evenodd"></path></svg>
                    Messages
                    </a>
                    <a className="dropdown-item d-flex align-items-center" href="#">
                    <svg className="dropdown-icon text-gray-400 me-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-2 0c0 .993-.241 1.929-.668 2.754l-1.524-1.525a3.997 3.997 0 00.078-2.183l1.562-1.562C15.802 8.249 16 9.1 16 10zm-5.165 3.913l1.58 1.58A5.98 5.98 0 0110 16a5.976 5.976 0 01-2.516-.552l1.562-1.562a4.006 4.006 0 001.789.027zm-4.677-2.796a4.002 4.002 0 01-.041-2.08l-.08.08-1.53-1.533A5.98 5.98 0 004 10c0 .954.223 1.856.619 2.657l1.54-1.54zm1.088-6.45A5.974 5.974 0 0110 4c.954 0 1.856.223 2.657.619l-1.54 1.54a4.002 4.002 0 00-2.346.033L7.246 4.668zM12 10a2 2 0 11-4 0 2 2 0 014 0z" clip-rule="evenodd"></path></svg>
                    Support
                    </a> */}
                    <div role="separator" className="dropdown-divider my-1"></div>
                    <button className="dropdown-item d-flex align-items-center" onClick={()=>userlogout()}>
                    <svg className="dropdown-icon text-danger me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path></svg>                
                    Logout
                    </button>
                </div>
                </li>
            </ul>
            </div>
        </div>
    </nav>
</header>
    )
}

export default Header;