import React, {useState, useEffect} from "react";
import Header from '../include/header';
import Footer from '../include/footer';
import LeftPanel from '../include/LeftPanel';
import Global from "../../APIConfig";
import  axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import MainLoader from "../Loaders/MainLoader";
import ListLoader from "../Loaders/ListLoader";
import AuthUser from "../../AuthUser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function Shipmentlist(){
    let navigate = useNavigate();
    let {type} = useParams(); 
    const [list, setList] = useState([]);
    const [searchtype, setSearchtype] = useState('Any');
    const [searchvalue, setSearchvalue] = useState('');
    const [showpage, setShowpage] = useState(type);
    const [searchstatus, setSearchstatus] = useState('');
    const [searchorigin, setSearchorigin] = useState('');
    const [searchdestination, setSearchdestination] = useState('');
    const [searchship, setSearchship] = useState('');
    const [searchstartdate, setSearchstartdate] = useState('');
    const [searchenddate, setSearchenddate] = useState('');
    const [searchcarrier, setSearchcarrier] = useState('');
    const [originList, setOriginList] = useState([]);
    const [destinationList, setDestinationList] = useState([]);
    const [originarray, setOriginarray] = useState('');
    const [destinationarray, setDestinationarray] = useState(''); 
    const [Loading, setLoading] = useState(true); 
    const [SubLoading, setSubLoading] = useState(false); 
    const{headers1,headers2,logout}=AuthUser();
    const [checkedItems, setCheckedItems] = useState([]);

    useEffect(()=>{
        GetShipmentList(type,'');
    },[]);
    const items = JSON.parse(localStorage.getItem('user'));

    const GetShipmentList=(type,val)=>{
        setSubLoading(true);
        setShowpage(type);
        setSearchvalue(val)
        axios({
            method: 'post',
            url: Global.baseUrl+'customershowshipmentlist',
            headers:headers1,
            data: {
                pagetype:type,
                usertype:items.usertype,
                custid:items.uniqueid,
                searchtype: searchtype,
                searchvalue: val,
                searchstatus: searchstatus,
                searchorigin: originarray,
                searchdestination: destinationarray,
                searchship: searchship,
                searchstartdate: searchstartdate,
                searchenddate: searchenddate,
                searchcarrier: searchcarrier,
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value.data;
                setList(data);
                setLoading(false);
                setSubLoading(false);
            }
            else
            {
                setList();
                setLoading(false);
                setSubLoading(false);
            }
          })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
    }

    const handelclearbutton=()=>{
        setSearchstatus('');
        setSearchship('');
        setSearchcarrier('');
        setOriginList([]);
        setDestinationList([]);
        setOriginarray('');
        setDestinationarray('');
        setSearchorigin('');
        setSearchdestination('');
        setSearchstartdate('');
        setSearchenddate('');
        GetShipmentList('','');
    }

    const handleshpmentstatus=(e)=>{
        const status = e.target.value;
        if(!searchstatus.includes(status)){
            setSearchstatus([...searchstatus,status]);
        }else{
            let arr=searchstatus.filter((item)=>item!==status);
            setSearchstatus(arr);
        }
    }

    const handleshpmentdata=(e)=>{
        const status = e.target.value;
        if(!searchship.includes(status)){
            setSearchship([...searchship,status]);
        }else{
            let arr=searchship.filter((item)=>item!==status);
            setSearchship(arr);
        }
    }

    const handleshpmentcarrier=(e)=>{
        const status = e.target.value;
        if(!searchcarrier.includes(status)){
            setSearchcarrier([...searchcarrier,status]);
        }else{
            let arr=searchcarrier.filter((item)=>item!==status);
            setSearchcarrier(arr);
        }
    }

    const showOrigincity=(val)=>{
        setSearchorigin(val);
        axios({
            method: 'post',
            url: Global.baseUrl+'GetCitylistbySearchValue',
            data: {
                cityvalue: searchorigin,

            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setOriginList(data);
            }
            else
            {
                setOriginList();
            }
          })
    }

    const showDestinationcity=(val)=>{
        setSearchdestination(val);
        axios({
            method: 'post',
            url: Global.baseUrl+'GetCitylistbySearchValue',
            data: {
                cityvalue: searchdestination,

            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                var data=response.data.value;
                setDestinationList(data);
            }
            else
            {
                setDestinationList();
            }
          })
    }

    const handleshpmentorigin=(e)=>{
        const status = e.target.value;
        if(!originarray.includes(status)){
            setOriginarray([...originarray,status]);
        }else{
            let arr=originarray.filter((item)=>item!==status);
            setOriginarray(arr);
        }
    }

    const handleshpmentdestination=(e)=>{
        const status = e.target.value;
        if(!destinationarray.includes(status)){
            setDestinationarray([...destinationarray,status]);
        }else{
            let arr=destinationarray.filter((item)=>item!==status);
            setDestinationarray(arr);
        }
    }

    const ShipmentDelete=(id)=>{
        if (window.confirm('Do You  want to delete ?')) {
            axios({
                method: 'post',
                url: Global.baseUrl+'CustomerShipmentDelete',
                headers:headers1,
                data: {
                    slip_no:id
                }
              })
              .then(function (response) {
                if(response.data.status == 200)
                {
                   toast.success(response.data.message);
                }
                else
                {
                    toast.warn(response.data.message);
                }
              })
              .catch(function (error) {
                if(error.response.status==401){
                    toast.error(error.response.statusText);
                    logout();
                    navigate('/');
                    window.location.reload();
                }
                // console.log(error);
              });
              GetShipmentList(showpage,'');
          } else {
            GetShipmentList(showpage,'');
          }
        
    }

    const handleChange = (event) => {
        const slip_no = event.target.value;
        if(!checkedItems.includes(slip_no)){
            setCheckedItems([...checkedItems,slip_no]);
        }else{
            let arr=checkedItems.filter((item)=>item!==slip_no);
            setCheckedItems(arr);
        }
        
      };

      const handleSelectAllChange = (event) => {
        if (event.target.checked) {
            const allSlipNos = list.map(item => item.slip_no);
            setCheckedItems(allSlipNos);
        } else {
            setCheckedItems([]);
        }
    };
   
    const isChecked = (slip_no) => checkedItems.includes(slip_no);

    const Printlabel=()=>{
        if(checkedItems.length > 0)
        {
            const explodedData = checkedItems.join("-");
            const url = `/ShipmentLabel?slipno=${explodedData}`;
            window.open(url, '_blank');
            // navigate(`/ShipmentLabel?slipno=${explodedData}`);
        }
        else
        {
            toast.warn("At Least One Shipment Seleted");
        }
    }
    const importshipment=()=>{

        if(checkedItems.length > 0)
        {
        axios({
            method: 'post',
            url: Global.baseUrl+'Customerselectedshipmentimport',
            headers:headers1,
            data: {
                slip_no:checkedItems
            }
          })
          .then(function (response) {
            if(response.data.status == 200)
            {
                toast.success("Excel Download Successfully");
                // Create a new workbook
                const wb = XLSX.utils.book_new();
            
                // Convert data to a worksheet
                const ws = XLSX.utils.json_to_sheet(response.data.excelvalue);
            
                // Append the worksheet to the workbook
                XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
            
                // Generate a buffer for the workbook
                const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            
                // Create a Blob object from the buffer
                const blob = new Blob([wbout], { type: 'application/octet-stream' });
            
                // Save the file using file-saver
                saveAs(blob, 'Shipmentdata.xlsx');
            }
            else{
                toast.warn(response.data.message);
            }
            })
          .catch(function (error) {
            if(error.response.status==401){
                toast.error(error.response.statusText);
                logout();
                navigate('/');
                window.location.reload();
            }
            // console.log(error);
          });
        }
        else
        {
            toast.warn("At Least One Shipment Seleted");
        }
    }

return(
    <>
        <LeftPanel/>
        <main className='home-section'>
            <Header/>
            <ToastContainer/>
            {Loading==true ? <MainLoader/> :
            <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                    <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                        <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                            <li className="breadcrumb-item">
                                <a href="/Admin-Dash">
                                <i className="bx bx-home text-primary-400 fs-6"></i>
                                </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Shipments</li>
                        </ol>
                    </nav>
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <button className="btn btn_nav btn-sm" type="button" onClick={() => navigate(-1)}>
                            <i className="bx bx-arrow-back "></i>
                            </button>
                        </div>
                        <div className="ps-2 ">
                            <h2 className="h4">All Shipments</h2>
                        </div>
                    </div>
                </div>
                
                <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="dropdown">
                        <button className="btn btn-primary d-inline-flex align-items-center me-2 dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                            New 
                        </button>
                        <div className="dropdown-menu dashboard-dropdown dropdown-menu-start mt-2 py-1">
                            <a className="dropdown-item d-flex align-items-center" href="/Createshipment">
                                <i className="bx bx-cube text-primary-400 me-2 fs-5"></i>
                                Add New Shipment
                            </a>
                            {/* <a className="dropdown-item d-flex align-items-center" href="/Bulkshipmentimport">
                                <i className="bx bx-upload text-primary-400 me-2 fs-5"></i>
                                Upload Bulk Shipment
                            </a> */}
                        </div>
                    </div>
                    
                    <div className="btn-group ms-2 ms-lg-3">
                        <button type="button" className="btn btn-sm btn-outline-gray-600" data-bs-toggle="tooltip" title="Print Label" onClick={()=>Printlabel()}><i className="bx bx-printer"></i> Print Label</button>
                        <button type="button" className="btn btn-sm btn-outline-gray-600" data-bs-toggle="tooltip" title="Export Data" onClick={()=>importshipment()}><i className="bx bx-import"></i> Export Data</button>
                        <button type="button" className="btn btn-sm btn-outline-gray-600"  data-bs-toggle="offcanvas" data-bs-target="#shipment_filter"><i className="bx bx-filter"></i> Filter</button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md">
                    <div className="custom_nav_data_table">
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                            <a href="/Shipmentlist/All" className={showpage =='All'? "nav-link active":"nav-link"}>All</a>
                            </li>
                            <li className="nav-item">
                            <a href="/Shipmentlist/B" className={showpage =='B'? "nav-link active":"nav-link"}>Booked</a>
                            </li>
                            <li className="nav-item">
                            <a href="/Shipmentlist/T" className={showpage =='T'? "nav-link active":"nav-link"}>Picked Up</a>
                            </li>
                            <li className="nav-item">
                            <a href="/Shipmentlist/IT" className={showpage =='IT'? "nav-link active":"nav-link"}>In Transit</a>
                            </li>
                            <li className="nav-item">
                            <a href="/Shipmentlist/D" className={showpage =='D'? "nav-link active":"nav-link"}>Delivered</a>
                            </li>
                            <li className="nav-item">
                            <a href="/Shipmentlist/C" className={showpage =='C'? "nav-link active":"nav-link"}>Cancel</a>
                            </li>
                            <li className="nav-item">
                            <a href="/Shipmentlist/R" className={showpage =='R'? "nav-link active":"nav-link"}>Return</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="table-settings shipment_list" id="">
                        <div className="row align-items-start g-2" >
                            <div className="col col-md ">
                                <div className="input-group ">
                                    <select className="form-select" onChange={(e)=>setSearchtype(e.target.value)}>
                                    <option value="Any">Any</option>
                                    <option value="AWB">AWB #</option>
                                    <option value="Order">Order #</option>
                                    <option value="Phone_Number">Phone Number</option>
                                    </select>
                                    <input type="text" className="form-control w-50" placeholder="Search shipments" value={searchvalue} onChange={(e)=>GetShipmentList(showpage,e.target.value)} />
                                    <span className="input-group-text">
                                        <i className="bx bx-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className="data_table">
                <div className="card card-body border-0 shadow table-wrapper table-responsive ">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="border-gray-200" style={{"width": "20px"}}>
                                {list !=undefined ?
                                    <input className="form-check-input" type="checkbox" onChange={handleSelectAllChange}  checked={checkedItems.length === list.length}/>
                                :
                                ''
                                }
                                </th>						
                                <th className="border-gray-200">AWB#</th>
                                <th className="border-gray-200">Booking Type</th>
                                <th className="border-gray-200">Origin</th>
                                <th className="border-gray-200">Destination</th>
                                <th className="border-gray-200">Sender</th>
                                <th className="border-gray-200">Receiver</th>
                                <th className="border-gray-200">Type</th>
                                <th className="border-gray-200">Status</th>
                                <th className="border-gray-200">Action</th>
                            </tr>
                        </thead>
                        {SubLoading==true? <tbody><tr><td colSpan={9}><ListLoader/></td></tr></tbody> : 
                            <tbody>
                                {list != undefined ? 
                                    <>
                                    {list.map((ship,index)=>{
                                        return(
                                    <tr key={index}>
                                        <td style={{"width": "20px"}}>
                                            <input className="form-check-input" type="checkbox" id={index} value={ship.slip_no} checked={isChecked(ship.slip_no)} onChange={handleChange} />
                                        </td>
                                       
                                        <td>
                                            <div>
                                                <Link to={`/ShipmentDetails/${ship.slip_no}`} className="fw-bold">{ship.slip_no}</Link>
                                                <CopyToClipboard text={ship.slip_no} onCopy={()=>{toast.success(ship.slip_no+' Copied To Clipboard')}}><i className="bx bx-copy"></i></CopyToClipboard>
                                            </div>
                                            <small>{ship.entrydate}</small>
                                        </td>
                                        <td>
                                        <span className="fw-normal text-capitalize">{ship.shipment_type}</span>
                                        </td>
                                        <td><span className="fw-normal text-capitalize">{ship.sendercity}, {ship.sendercountry}</span></td>
                                        <td><span className="fw-normal text-capitalize">{ship.receivercity}, {ship.receivercountry}</span></td>
                                        <td><span className="fw-normal text-capitalize">{ship.sender_name}</span><br />
                                        <span className="fw-normal text-capitalize">{ship.sender_company_name}</span></td>
                                        <td><span className="fw-normal text-capitalize">{ship.reciever_name}</span><br />
                                        <span className="fw-normal text-capitalize">{ship.receiver_company_name}</span></td>
                                        <td>
                                        <span className="fw-normal"><span className="badge bg-primary">{ship.nrd}</span></span><br />
                                            {ship.booking_mode =='Prepaid'?
                                            <span className="fw-normal"><span className="badge bg-danger">Prepaid</span></span>
                                            :
                                            <span className="fw-normal"><span className="badge bg-primary">COD</span></span>
                                            }
                                            
                                        </td>
                                        <td><span dangerouslySetInnerHTML={{__html:ship.shipmentstatus}}></span></td>
                                        <td>
                                            <Link to={`/ShipmentLabel?slipno=${ship.slip_no}`} target="_blank" className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Download Label"><i className="bx bx-barcode"></i></Link>
                                            <Link to={`/ShipmentPrintReceipt/${ship.slip_no}`} target="_blank" className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Print Receipt"><i className="bx bx-printer"></i></Link>
                                            <Link to={`/ShipmentInvoice/${ship.slip_no}`} target="_blank" className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Print Invoice"><i className="bx bx-file"></i></Link>
                                            <Link to={`/ConsignmentNote/${ship.slip_no}`} target="_blank" className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Consignment Note"><i class='bx bx-calendar-week'></i></Link>
                                            <button className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Delete" onClick={()=>ShipmentDelete(ship.slip_no)}><i className="bx bx-x"></i></button>
                                            {ship.delivered =='B'&&
                                            <Link to={`/ShipmentEdit/${ship.slip_no}`} className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Update Status"><i className="bx bx-edit"></i></Link>
                                            }
                                            <Link to={`/ShipmentUpdateStatus/${ship.slip_no}`} className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Update Status"><i className="bx bx-revision"></i></Link>
                                            {ship.shipment_type =='International'&&
                                            <a href="#" className="btn btn-gray-100 btn-sm" data-bs-toggle="tooltip" title="Forward through"><i className="bx bx-move-horizontal"></i></a>
                                            }
                                        </td>
                                    </tr> 
                                    );
                                })}   
                                    </>
                                :
                                    <tr>
                                        <td colSpan="9" className="text-center">Record Not Found</td>
                                    </tr>
                                }                    
                            </tbody>
                        }
                    </table>
                    
                </div>
            </section>

            <div className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <nav aria-label="Page navigation example">
                    <ul className="pagination mb-0">
                        <li className="page-item">
                            <a className="page-link" href="#">Previous</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">1</a>
                        </li>
                        <li className="page-item active">
                            <a className="page-link" href="#">2</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">3</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">4</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">5</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
                <div className="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
            </div>
        
            <div className="offcanvas offcanvas-end bg-white" id="shipment_filter">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">Shipment Filter</h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
                </div>
                <div className="offcanvas-body">


            
                    <div className="accordion shipment_filter_side" id="accordionPricing">
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Shipment Status
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionPricing">
                            <div className="accordion-body">
                                <div className="mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={searchstatus.includes('All') || false} value="All" onChange={handleshpmentstatus} />
                                        <label className="form-check-label" for="defaultCheck10">
                                            All
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={searchstatus.includes('B') || false} value="B" onChange={handleshpmentstatus} />
                                        <label className="form-check-label">
                                            Booked
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={searchstatus.includes('T') || false} value="T" onChange={handleshpmentstatus} />
                                        <label className="form-check-label">
                                            Pikced Up
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={searchstatus.includes('IT') || false} value="IT" onChange={handleshpmentstatus} />
                                        <label className="form-check-label">
                                            In Transit
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={searchstatus.includes('C') || false} value="C" onChange={handleshpmentstatus} />
                                        <label className="form-check-label">
                                            Canceled
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={searchstatus.includes('D') || false} value="D" onChange={handleshpmentstatus} />
                                        <label className="form-check-label">
                                            Delivered
                                        </label>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        </div>
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Location
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionPricing">
                            <div className="accordion-body">
                                <div className="mb-3">
                                    <h6>Origin</h6>
                                    <div className="mb-3">
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                            <svg className="icon icon-xxs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                            </span>
                                            <input type="text" className="form-control" value={searchorigin} placeholder="Search Location" aria-label="Search" onChange={(e)=>showOrigincity(e.target.value)}/>
                                        </div>
                                    </div>
                                    {originList != undefined ? 
                                    <>
                                    {originList.map((lab,i)=>{
                                        return(
                                        <div className="form-check" key={i}>
                                        <input className="form-check-input" type="checkbox" checked={originarray.includes(lab.id)} value={lab.id} onChange={(e)=>handleshpmentorigin(e)} />
                                        <label className="form-check-label">
                                            {lab.city}
                                        </label>
                                    </div>
                                    );
                                    })}
                                    </>
                                    :
                                    ''
                                    } 
                                </div>
                                <div className="mb-3">
                                    <h6>Destination</h6>
                                    <div className="mb-3">
                                        <div className="input-group">
                                            <span className="input-group-text" id="basic-addon1">
                                            <svg className="icon icon-xxs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                            </span>
                                            <input type="text" className="form-control" value={searchdestination} placeholder="Search Location" aria-label="Search" onChange={(e)=>showDestinationcity(e.target.value)} />
                                        </div>
                                    </div>
                                    {destinationList != undefined ? 
                                    <>
                                    {destinationList.map((city,inde)=>{
                                        return(
                                        <div className="form-check" key={inde}>
                                        <input className="form-check-input" checked={destinationarray.includes(city.id) || false} type="checkbox" value={city.id} onChange={(e)=>handleshpmentdestination(e)} />
                                        <label className="form-check-label">
                                            {city.city}
                                        </label>
                                    </div>
                                    );
                                    })}
                                    </>
                                    :
                                    ''
                                    }  
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Shipment Data
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionPricing">
                            <div className="accordion-body">
                                <div className="mb-3">
                                    <h6>Shipment</h6>
                                    <hr/>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={searchship.includes('Domestic') || false} value="Domestic" onChange={handleshpmentdata}  />
                                        <label className="form-check-label">
                                            Domestic
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={searchship.includes('International') || false} value="International" onChange={handleshpmentdata}  />
                                        <label className="form-check-label">
                                            International
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <h6>Shipment Type</h6>
                                    <hr/>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={searchship.includes('Parcel') || false} value="Parcel" onChange={handleshpmentdata}  />
                                        <label className="form-check-label">
                                            Parcel
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={searchship.includes('Document') || false} value="Document" onChange={handleshpmentdata}  />
                                        <label className="form-check-label">
                                            Document
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <h6>Shipment Payment</h6>
                                    <hr/>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={searchship.includes('Prepaid') || false} value="Prepaid" onChange={handleshpmentdata}  />
                                        <label className="form-check-label">
                                            Prepaid
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={searchship.includes('COD') || false} value="COD" onChange={handleshpmentdata}  />
                                        <label className="form-check-label">
                                            COD (Cash on delivery)
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoten" aria-expanded="false" aria-controls="collapseTwoten">
                                Date Range
                            </button>
                            </h2>
                            <div id="collapseTwoten" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionPricing">
                            <div className="accordion-body">
                                <div className="mb-3">
                                    <label className="form-label">From Date</label>
                                    <input type="date" className="form-control" value={searchstartdate} onChange={(e)=>setSearchstartdate(e.target.value)}/>
                                </div>
                                <div className="">
                                    <label className="form-label">To Date</label>
                                    <input type="date" className="form-control" value={searchenddate} onChange={(e)=>setSearchenddate(e.target.value)}/>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                Carriers
                            </button>
                        </h2>
                        <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionPricing">
                            <div className="accordion-body">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={searchcarrier.includes('Any') || false} value="Any" onChange={handleshpmentcarrier} />
                                    <label className="form-check-label">
                                        Any
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={searchcarrier.includes('DHL') || false} value="DHL" onChange={handleshpmentcarrier} />
                                    <label className="form-check-label">
                                        DHL
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" checked={searchcarrier.includes('Fedex') || false} value="Fedex" onChange={handleshpmentcarrier} />
                                    <label className="form-check-label">
                                        Fedex
                                    </label>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="row justify-content-end my-3">
                        <div className="col-12 col-md-8">
                            <div className="d-grid">
                            <button className="btn btn-primary btn-block" onClick={()=>GetShipmentList(showpage,'')}>Apply</button>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="d-grid">
                            <button className="btn btn-outline-primary btn-block" onClick={()=>handelclearbutton()}>Clear</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            }
            <Footer/>
        </main>
    </>
);
}
export default Shipmentlist;