import { useState,useEffect } from 'react';
import { useNavigate,useParams } from 'react-router-dom';
import axios from 'axios';
import Global from '../../APIConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthUser from '../../AuthUser';
import SpinnerLoader from '../Loaders/Spinner';

function Register() {
  let navigate = useNavigate();
  const{headers1,headers2,logout}=AuthUser();
  const [logo, setLogo] = useState('');
  const [Name, setName] = useState('');
    const [Company, setCompany] = useState('');
    const [Phone, setPhone] = useState('');
    const [Email, setEmail] = useState('');
    const [Zip, setZip] = useState('');
    const [Country, setCountry] = useState('');
    const [City, setCity] = useState('');
    const [Hub, setHub] = useState('');
    const [Password, setPassword] = useState('');
    const [ConfirmPass, setConfirmPass] = useState('');
    const [Address, setAddress] = useState('');
    const [Countrylist, setCountrylist] = useState([]);
    const [Citylist, setCitylist] = useState([]);
    const [Hublist, setHublist] = useState([]);
    const [Loading, setLoading] = useState(true);
    const [Subloading, setSubloading] = useState(false);


  useEffect(()=>{
    systemdefaultdata();
    GetCountrylist();
    const balls = document.querySelectorAll('.ball');

    balls.forEach(ball => {
      const randomDuration = Math.random() * 10 + 50; // Random duration between 5 and 15 seconds
      const randomDirectionX = Math.random() * 2 - 1; // Random direction between -1 and 1
      const randomDirectionY = Math.random() * 2 - 1; // Random direction between -1 and 1
      const scaleMatch = ball.style.transform.match(/scale\((.*?)\)/);
      const scale = scaleMatch ? scaleMatch[1] : 1; // Default scale to 1 if not found

      ball.style.setProperty('--animation-duration', `${randomDuration}s`);
      ball.style.setProperty('--directionX', randomDirectionX);
      ball.style.setProperty('--directionY', randomDirectionY);
      ball.style.setProperty('--scale', scale);
    });
  },[]);

  const systemdefaultdata = () =>
  {
    axios({
      method: 'post',
      url: Global.baseUrl+'systemdefaultlogo',
      data: {
      }
    })
    .then(function (response) {
      if(response.data.status == 200)
      {
        setLogo(response.data.value);
      }
      else
      {
        setLogo('');
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  
  const handleSubmit=()=>{
    if(Name==""){
         toast.warning('Customer Name is Required');
     }
     else if(Company==""){
         toast.warning('Company Name is Required');
     }
     else if(Phone==""){
         toast.warning('Customer Phone NO. is Required');
     }
     else if(Email==""){
         toast.warning('Customer Email is Required');
     }
     else if(Password==""){
        toast.warning('Password is Required');
    }
    else if(ConfirmPass==""){
        toast.warning('Confirm Password is Required');
    }
    else if(Password != ConfirmPass){
      toast.warning('Password and Confirm Password Does Not Match');
    }
     else if(Zip==""){
         toast.warning('Postal Code is Required');
     }
     else if(Country==""){
         toast.warning('Please Select Country');
     }
     else if(City==""){
         toast.warning('Please Select City');
     }
    //  else if(Hub==""){
    //      toast.warning('Please Select Hub');
    //  }
     else if(Address==""){
         toast.warning('Address is Required');
     }
     else{
         setSubloading(true);
         axios({
             method: 'post',
             url: Global.baseUrl+'RegisterCustomer',
             data: {
                 Name:Name,
                 Company:Company,
                 Phone:Phone,
                 Email:Email,
                 Zip:Zip,
                 Country:Country,
                 City:City,
                 Hub:Hub,
                 Address:Address,
                 Password,Password,
                 ConfirmPass,ConfirmPass
            }
           })
           .then(function (response) {
            console.log("///////////",response.data.status);
             if(response.data.status == 200)
             {
                 setSubloading(false);
                 toast.success(response.data.message);
                 setTimeout(() => {
                     navigate('/Otp?email='+Email);
                   }, 1000);
             }else{
                 setSubloading(false);
                 if(response.data.message.Email)
                 {
                   toast.warning(response.data.message.Email[0]);
                 }
                 if(response.data.message.Phone)
                  {
                    toast.warning(response.data.message.Phone[0]);
                  }
                  toast.warning(response.data.message.Email);
                  
             }
           })
           .catch(function (error) {
             if(error.response.status==401){
                 toast.error(error.response.statusText);
                 logout();
                 navigate('/');
                 window.location.reload();
             }
             setSubloading(false);
           });
     }
 }

  const GetCountrylist=()=>{
    axios({
        method: 'post',
        url: Global.baseUrl+'Countrylist',
        data: {
        }
      })
      .then(function (response) {
        if(response.data.status == 200)
        {
            var data=response.data.value;
            setCountrylist(data);
        }
      })
      .catch(function (error) {
        // console.log(error);
        // setLoading(false);
      });
}
const GetCityList=(val)=>{
    axios({
        method: 'post',
        url: Global.baseUrl+'Citylist',
        data: {
            name: val
        }
      })
      .then(function (response) {
        if(response.data.status == 200)
        {
            var data=response.data.value;
            setCitylist(data);
        }
      })
      .catch(function (error) {
        // console.log(error);
        // setLoading(false);
      });
}
const handleCountryChange = (val) => {
    setCountry(val);
    GetCityList(val);
};
const handleCityChange = (val) => {
    setCity(val);
    GetHubList(val);
};
const GetHubList=(val)=>{
    axios({
        method: 'post',
        url: Global.baseUrl+'Hublist',
        data: {
            cityid: val
        }
      })
      .then(function (response) {
        if(response.data.status == 200)
        {
            var data=response.data.value;
            setHublist(data);
        }
      })
      .catch(function (error) {
        // console.log(error);
        // setLoading(false);
      });
}

  return (
    <>
    <section className="section-balls">
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "54%", "top": "28%", "transform": "scale(0.384616)", "width": "0.503075px", "height": "0.503075px"}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "55%", "top": "76%", "transform": "scale(0.547928)","width": "2.61835px", "height": "2.61835px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "84%", "top": "99%", "transform": "scale(0.900603)" ,"width": "0.0499738px","height": "0.0499738px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "5%", "top": "1%", "transform": "scale(0.146395)" ,"width": "2.35144px", "height": "2.35144px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "55%", "top": "85%", "transform": "scale(0.334319)" ,"width": "9.06381px", "height": "9.06381px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "86%", "top": "52%", "transform": "scale(0.155746)" ,"width": "3.9894px", "height": "3.9894px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "65%", "top": "27%", "transform": "scale(0.86821)" ,"width": "5.35571px", "height": "5.35571px"}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "54%", "top": "91%", "transform": "scale(0.953563)" ,"width": "9.97993px", "height": "9.97993px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "66%", "top": "38%", "transform": "scale(0.644339)" ,"width": "0.0201365px", "height": "0.0201365px"}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "64%", "top": "94%", "transform": "scale(0.583572)" ,"width": "2.17545px", "height": "2.17545px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "65%", "top": "87%", "transform": "scale(0.983493)" ,"width": "3.17685px", "height": "3.17685px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "18%", "top": "6%", "transform": "scale(0.719261)" ,"width": "9.21264px", "height": "9.21264px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "81%", "top": "61%", "transform": "scale(0.112961)" ,"width": "3.48616px", "height": "3.48616px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "23%", "top": "38%", "transform": "scale(0.0822419)" ,"width": "3.97773px", "height": "3.97773px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "60%", "top": "29%", "transform": "scale(0.973699)" ,"width": "9.32737px", "height": "9.32737px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "55%", "top": "51%", "transform": "scale(0.672809)" ,"width": "9.91568px", "height": "9.91568px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "34%", "top": "61%", "transform": "scale(0.388619)" ,"width": "1.06271px", "height": "1.06271px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "73%", "top": "8%", "transform": "scale(0.326254)" ,"width": "4.81457px", "height": "4.81457px"}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "97%", "top": "32%", "transform": "scale(0.831007)" ,"width": "0.166249px", "height": "0.166249px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "41%", "top": "52%", "transform": "scale(0.618639)" ,"width": "3.96113px", "height": "3.96113px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "41%", "top": "26%", "transform": "scale(0.357081)" ,"width": "9.74586px", "height": "9.74586px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "18%", "top": "79%", "transform": "scale(0.901469)" ,"width": "6.06944px", "height": "6.06944px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "88%", "top": "56%", "transform": "scale(0.486343)" ,"width": "8.82203px", "height": "8.82203px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "45%", "top": "95%", "transform": "scale(0.827023)" ,"width": "3.97533px", "height": "3.97533px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "98%", "top": "86%", "transform": "scale(0.711744)" ,"width": "1.0264px", "height": "1.0264px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "78%", "top": "75%", "transform": "scale(0.566407)" ,"width": "9.63597px", "height": "9.63597px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "45%", "top": "65%", "transform": "scale(0.506611)" ,"width": "2.78299px", "height": "2.78299px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "11%", "top": "99%", "transform": "scale(0.970328)" ,"width": "6.045px", "height": "6.045px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "89%", "top": "69%", "transform": "scale(0.101904)" ,"width": "3.97999px", "height": "3.97999px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "84%", "top": "86%", "transform": "scale(0.801288)" ,"width": "2.53842px", "height": "2.53842px"}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "54%", "top": "28%", "transform": "scale(0.384616)" ,"width": "0.503075px", "height": "0.503075px"}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "55%", "top": "76%", "transform": "scale(0.547928)","width": "2.61835px", "height": "2.61835px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "84%", "top": "99%", "transform": "scale(0.900603)" ,"width": "0.0499738px","height": "0.0499738px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "5%", "top": "1%", "transform": "scale(0.146395)" ,"width": "12.35144px", "height": "12.35144px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "55%", "top": "85%", "transform": "scale(0.334319)" ,"width": "9.06381px", "height": "9.06381px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "86%", "top": "52%", "transform": "scale(0.155746)" ,"width": "3.9894px", "height": "3.9894px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "65%", "top": "27%", "transform": "scale(0.86821)" ,"width": "5.35571px", "height": "5.35571px",}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "54%", "top": "91%", "transform": "scale(0.953563)" ,"width": "9.97993px", "height": "9.97993px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "66%", "top": "38%", "transform": "scale(0.644339)" ,"width": "0.0201365px", "height": "0.0201365px",}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "64%", "top": "94%", "transform": "scale(0.583572)" ,"width": "2.17545px", "height": "2.17545px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "65%", "top": "87%", "transform": "scale(0.983493)" ,"width": "3.17685px", "height": "3.17685px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "18%", "top": "6%", "transform": "scale(0.719261)" ,"width": "19.21264px", "height": "19.21264px"}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "81%", "top": "61%", "transform": "scale(0.112961)" ,"width": "3.48616px", "height": "3.48616px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "23%", "top": "38%", "transform": "scale(0.0822419)" ,"width": "3.97773px", "height": "3.97773px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "60%", "top": "29%", "transform": "scale(0.973699)" ,"width": "9.32737px", "height": "9.32737px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "55%", "top": "51%", "transform": "scale(0.672809)" ,"width": "9.91568px", "height": "9.91568px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "34%", "top": "61%", "transform": "scale(0.388619)" ,"width": "1.06271px", "height": "1.06271px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "73%", "top": "8%", "transform": "scale(0.326254)" ,"width": "4.81457px", "height": "4.81457px",}}></div>
        <div className="ball" style={{"background": "rgb(187, 107, 217)", "left": "97%", "top": "32%", "transform": "scale(0.831007)" ,"width": "0.166249px", "height": "0.166249px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "41%", "top": "52%", "transform": "scale(0.618639)" ,"width": "13.96113px", "height": "13.96113px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "41%", "top": "26%", "transform": "scale(0.357081)" ,"width": "9.74586px", "height": "9.74586px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "18%", "top": "79%", "transform": "scale(0.901469)" ,"width": "6.06944px", "height": "6.06944px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "88%", "top": "56%", "transform": "scale(0.486343)" ,"width": "8.82203px", "height": "8.82203px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "45%", "top": "95%", "transform": "scale(0.827023)" ,"width": "6.97533px", "height": "6.97533px"}}></div>
        <div className="ball" style={{"background": "rgb(255, 77, 77)", "left": "98%", "top": "86%", "transform": "scale(0.711744)" ,"width": "1.0264px", "height": "1.0264px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "78%", "top": "75%", "transform": "scale(0.566407)" ,"width": "9.63597px", "height": "9.63597px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "45%", "top": "65%", "transform": "scale(0.506611)" ,"width": "2.78299px", "height": "2.78299px",}}></div>
        <div className="ball" style={{"background": "rgb(255, 205, 77)", "left": "11%", "top": "99%", "transform": "scale(0.970328)" ,"width": "6.045px", "height": "6.045px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 214, 255)", "left": "89%", "top": "69%", "transform": "scale(0.101904)" ,"width": "3.97999px", "height": "3.97999px",}}></div>
        <div className="ball" style={{"background": "rgb(26, 102, 255)", "left": "84%", "top": "86%", "transform": "scale(0.801288)" ,"width": "2.53842px", "height": "2.53842px",}}></div>
    </section>
    <section className=" register_box_00 login-22-bodycolor">
    <ToastContainer/>
      {Loading==true && <SpinnerLoader/> }
      <nav className="navbar navbar-expand-sm pt-4">
          <div className="container">
            <div className='col-12 col-md-12'>
              <div className='row'>
                <div className='col-12 col-md-10'>
                  <img src={`${Global.logoUrl}${logo}`} className="img-fluid" />
                </div>
                <div className='col-12 col-md-2'>
                  <a href="/" className="btn btn-primary btn-block">Login Now</a>
                </div>
              </div>
            </div>
          </div>
      </nav>
      <div className='container'>
      <div className="card">
        <div className="card-body">
        <h2>Registration</h2>
            <div className="row">
                <div className="col-12 mb-3 mt-2">
                    <strong>Please enter your deatils to register a account</strong>
                    <div className="row mt-4">
                        <div className="col-4 mb-3">
                            <label for="" className="form-label">Name: <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" onChange={(e)=>setName(e.target.value)} placeholder="Please enter customer name" />
                        </div>
                        <div className="col-4 mb-3">
                            <label for="" className="form-label">Company Name: <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" onChange={(e)=>setCompany(e.target.value)} placeholder="Please enter customer company name" />
                        </div>
                        <div className="col-4 mb-3">
                            <label for="" className="form-label">Phone: <span className="text-danger">*</span></label>
                            <input type="number" className="form-control" onChange={(e)=>setPhone(e.target.value)} placeholder="Please enter customer phone no." />
                        </div>
                        <div className="col-4 mb-3">
                            <label for="" className="form-label">Email: <span className="text-danger">*</span></label>
                            <input type="email" className="form-control" onChange={(e)=>setEmail(e.target.value)} placeholder="Please enter customer email" />
                        </div>
                        <div className="col-4 mb-3">
                            <label for="" className="form-label">Password: <span className="text-danger">*</span></label>
                            <input type="password" className="form-control" onChange={(e)=>setPassword(e.target.value)} placeholder="Please enter Password" />
                        </div>
                        <div className="col-4 mb-3">
                            <label for="" className="form-label">Confirm Password: <span className="text-danger">*</span></label>
                            <input type="password" className="form-control" onChange={(e)=>setConfirmPass(e.target.value)} placeholder="Please enter Confirm Password" />
                        </div>
                        <div className="col-4 mb-3">
                            <label for="" className="form-label">Postal Code: <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" onChange={(e)=>setZip(e.target.value)} placeholder="Please enter customer postal code" />
                        </div>
                        <div className="col-4 mb-3">
                            <div><label for="email" className="form-label">Country: <span className="text-danger">*</span></label></div>
                            <select className="form-select" onChange={(e)=>handleCountryChange(e.target.value)}>
                                <option value="">-- Please Select --</option>
                                {Countrylist.map((list)=>{
                                    return (
                                        <option value={list.country}>{list.country}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-4 mb-3">
                            <div><label for="email" className="form-label">City: <span className="text-danger">*</span></label></div>
                            <select className="form-select" onChange={(e)=>handleCityChange(e.target.value)}>
                                <option value="">-- Please Select --</option>
                                {Citylist.map((list)=>{
                                    return (
                                        <option value={list.value}>{list.label}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-4 mb-3">
                            <div><label for="email" className="form-label">Hub: <span className="text-danger"></span></label></div>
                            <select className="form-select" onChange={(e)=>setHub(e.target.value)}>
                                <option value="">-- Please Select --</option>
                                {Hublist.map((list)=>{
                                    return (
                                        <option value={list.value}>{list.label}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-12 mb-3">
                            <label for="" className="form-label">Address: <span className="text-danger">*</span></label>
                            <textarea className="form-control" rows={5} onChange={(e)=>setAddress(e.target.value)} placeholder="Please enter customer address" ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            {Subloading==false?
            <div className="d-grid col-md-2 mt-2">
                <button className="btn btn-primary" onClick={()=>handleSubmit()}>Submit</button>
            </div>
            :
            <div className="d-grid col-md-2 mt-2">
                <a href="" className="btn btn-primary btn-block"> <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div></a>
            </div>
            }
        </div>
        </div>
    </div>
    </section>
</>
  );
}

export default Register;